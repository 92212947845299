const Images = {
  logoCamera: require('../Images/logo-camera-only.svg').default,
  locked: require('../Images/locked.svg').default,
  logoCameraSupport: require('../Images/logo-camera-only-support.svg').default,
  crmLogo: require('../Images/crm-app-logo.svg').default,
  galleriesLogo: require('../Images/galleries-app-logo.svg').default,
  calendar: require('../Images/calendar.svg').default,
  contact: require('../Images/contact.svg').default,
  copyright: require('../Images/copyright.svg').default,
  customFields: require('../Images/custom-fields.svg').default,
  email: require('../Images/email.svg').default,
  money: require('../Images/money.svg').default,
  packages: require('../Images/packages.svg').default,
  prospect: require('../Images/prospect.svg').default,
  prospectOrigin: require('../Images/binoculars.svg').default,
  gallery: require('../Images/gallery.svg').default,
  shootingStatus: require('../Images/project-status.svg').default,
  shootingTypes: require('../Images/shooting-types.svg').default,
  shooting: require('../Images/shooting.svg').default,
  survey: require('../Images/survey.svg').default,
  tasks: require('../Images/tasks.svg').default,
  taskType: require('../Images/task-type.svg').default,
  tag: require('../Images/tag.svg').default,
  upload: require('../Images/upload.svg').default,
  download: require('../Images/download.svg').default,
  authIllustration: require('../Images/illustration-authentication.svg').default,
  scrollDownIndicator: require('../Images/scroll-down-indicator.svg').default,
  dateSeparator: require('../Images/date-separator.svg').default,
  maintenance: require('../Images/maintenance.svg').default,
  // Gallery grid config
  gridSpacedLargePictures: require('../Images/grid-spaced-large-pictures.svg').default,
  gridSpacedNormalPictures: require('../Images/grid-spaced-normal-pictures.svg').default,
  gridSpacedSmallPictures: require('../Images/grid-spaced-small-pictures.svg').default,
  gridNormalLargePictures: require('../Images/grid-normal-large-pictures.svg').default,
  gridNormalNormalPictures: require('../Images/grid-normal-normal-pictures.svg').default,
  gridNormalSmallPictures: require('../Images/grid-normal-small-pictures.svg').default,
  gridSmallLargePictures: require('../Images/grid-small-large-pictures.svg').default,
  gridSmallNormalPictures: require('../Images/grid-small-normal-pictures.svg').default,
  gridSmallSmallPictures: require('../Images/grid-small-small-pictures.svg').default,
  gridTinySmallPictures: require('../Images/grid-tiny-small-pictures.svg').default,
  gridTinyNormalPictures: require('../Images/grid-tiny-normal-pictures.svg').default,
  gridTinyLargePictures: require('../Images/grid-tiny-large-pictures.svg').default,
  // Watemark previews
  watermarkPreview1: require('../Images/watermark-preview-1.jpg'),
  // cover previews
  coverPreview1: require('../Images/cover-preview-1.jpg'),
  // Gallery Cover
  coverAlpha: require('../Images/cover-alpha.jpg'),
  coverBeta: require('../Images/cover-beta.jpg'),
  coverGamma: require('../Images/cover-gamma.jpg'),
  coverDelta: require('../Images/cover-delta.jpg'),
  coverEpsilon: require('../Images/cover-epsilon.jpg'),
  coverZeta: require('../Images/cover-zeta.jpg'),
  coverMissing: require('../Images/cover-missing.jpg'),
  coverPortraitMissing: require('../Images/cover-missing-portrait.jpg'),
  // UploadClient
  uploadClientBackground: require('../Images/download-client-background.png'),
  uploadClientBackgroundX2: require('../Images/download-client-background@2x.png'),
  uploadClientPreview: require('../Images/download-client-preview.png'),
  uploadClientPreviewX2: require('../Images/download-client-preview@2x.png'),
  // Mollie
  mollieConnect: require('../Images/mollie-connect.png'),
  // Stripe
  stripeConnect: require('../Images/stripe-connect@2x.png'),
  // Product
  productMissing: require('../Images/product-missing.jpg'),
  // Product Details
  productDetailsMissing: require('../Images/product-details-missing.jpg'),
  // Theme preview
  themePreviewDflt: require('../Images/theme-dflt.jpg'),
  themePreviewGradient: require('../Images/theme-gradient.jpg'),
  themePreviewLight: require('../Images/theme-light.jpg'),
  // Black Friday
  blackFridayFr: require('../Images/black-friday/fotostudio-black-friday-fr.jpg'),
  blackFridayEn: require('../Images/black-friday/fotostudio-black-friday-en.jpg'),
  blackFridayEs: require('../Images/black-friday/fotostudio-black-friday-es.jpg'),
  blackFridayIt: require('../Images/black-friday/fotostudio-black-friday-it.jpg'),
  blackFridayDe: require('../Images/black-friday/fotostudio-black-friday-de.jpg'),
  blackFridayNl: require('../Images/black-friday/fotostudio-black-friday-nl.jpg'),
};

export default Images;
