import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { RefreshTokenPayload } from 'types/Token';

import { decodeJwt } from 'jose';

import i18n from 'i18n';

import { getApolloClient } from 'Providers/ApolloProvider';

import { initCrispService, resetCrisp } from 'Services/CrispService';
import dayjs from 'Services/DayjsService';

import { AuthVar } from 'Operations/Cache';

import { GET_STORAGE } from 'Operations/Queries/Storage/GetStorage';
import { ME } from 'Operations/Queries/User/Me';

import CreateApp from 'Operations/Mutations/App/CreateApp';
import CreateAuth from 'Operations/Mutations/Auth/CreateAuth';
import { REFRESH } from 'Operations/Mutations/Auth/Refresh';
import { SIGN_OUT } from 'Operations/Mutations/Auth/SignOut';
import { INITIALIZE_DEFAULT_DATA } from 'Operations/Mutations/User/InitializeDefaultData';

export const onRefreshSession = async ({ refreshClient }: { refreshClient: () => void }) => {
  try {
    const apolloClient = getApolloClient();
    const authVar = AuthVar();

    if (!authVar.refreshToken) {
      await onAppLogout();
      return null;
    }

    const { data: refreshData } = await apolloClient.mutate({
      mutation: REFRESH,
      variables: {
        data: {
          refreshToken: authVar.refreshToken,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (refreshData) {
      const { isSupport } = (refreshData.refresh.refreshToken
        ? decodeJwt(refreshData.refresh.refreshToken) || {}
        : {}) as unknown as RefreshTokenPayload;

      CreateAuth({
        isLoggedIn: true,
        isSupport: isSupport ?? false,
        accessToken: refreshData.refresh.accessToken,
        refreshToken: refreshData.refresh.refreshToken,
      });

      refreshClient();
    }
  } catch (error) {
    console.log('error & session clear', error);
    await onAppLogout();
  }
};

const loadUserData = async ({
  client,
  setLocale,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  setLocale: (locale: string) => void;
}) => {
  try {
    const meResponse = await client.query({
      query: ME,
      fetchPolicy: 'network-only',
    });

    const storageResponse = await client.query({
      query: GET_STORAGE,
      fetchPolicy: 'network-only',
    });

    if (meResponse.data) {
      const meLocale = meResponse.data.me.locale;
      i18n.locale = meLocale;
      dayjs.locale(meLocale);

      if (setLocale) {
        setLocale(meLocale);
      }

      initCrispService(meResponse.data);
    }

    if (storageResponse.data) {
      CreateApp({
        hasFreeSpace: storageResponse.data.getStorage.maxSpace > storageResponse.data.getStorage.usedSpace,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const onAppStartup = async () => {
  const apolloClient = getApolloClient();

  const startInitDefaultData = async ({ accessToken }: { accessToken: string }) => {
    await apolloClient.mutate({
      mutation: INITIALIZE_DEFAULT_DATA,
      variables: {
        data: {
          accessToken,
        },
      },
    });
  };

  // Handle receive message from CRM to logged in user
  window.onmessage = async (e: MessageEvent) => {
    const origin = e.origin;

    if (origin !== process.env.REACT_APP_CRM_URL) {
      console.log(`${origin} !== ${process.env.REACT_APP_CRM_URL}`);
      return;
    }

    try {
      const payload = typeof e.data === 'string' ? JSON.parse(e.data || '{}') : e?.data || {};
      const { data } = payload;

      if (!data) {
        return;
      }

      const { isSupport } = (data.refreshToken
        ? decodeJwt(data.refreshToken) || {}
        : {}) as unknown as RefreshTokenPayload;

      CreateAuth({
        isLoggedIn: true,
        isSupport: isSupport ?? false,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      });

      startInitDefaultData({ accessToken: data.accessToken });
    } catch (error) {
      console.log(error);
    }
  };
};

export const onAppLoading = async ({
  client,
  setLocale,
  isPublicPage,
  isLoggedIn,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  setLocale: (locale: string) => void;
  isPublicPage: boolean;
  isLoggedIn: boolean;
}) => {
  try {
    if (isLoggedIn && !isPublicPage) {
      await loadUserData({ client, setLocale });
    }
  } catch (error) {
    console.error(error);
  }
};

export const onAppLogout = async () => {
  try {
    const apolloClient = getApolloClient();
    const { refreshToken } = AuthVar();

    resetCrisp();

    if (refreshToken) {
      await apolloClient.mutate({
        mutation: SIGN_OUT,
        variables: {
          data: {
            refreshToken,
          },
        },
      });
    }

    CreateAuth({
      isLoggedIn: false,
      isSupport: false,
      accessToken: undefined,
      refreshToken: undefined,
    });

    await apolloClient.clearStore();
    await apolloClient.resetStore();
  } catch (error) {
    console.error(error);
  }
};
