import { useCallback, useEffect, useState } from 'react';

import Maintenance from 'Pages/App/Maintenance';

import { onAppStartup } from 'Services/AppLifeCycle';

import sleep from 'Helpers/Sleep';

const APPLICATION_CONFIG_URL = process.env.REACT_APP_APPLICATION_CONFIG_URL;

const AppLifecycleProvider = ({ children }: { children: JSX.Element }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMaintenance, setisMaintenance] = useState(false);

  const init = useCallback(async () => {
    try {
      const checkMaintenance = async () => {
        try {
          if (!APPLICATION_CONFIG_URL) {
            throw new Error('Application config url is not defined');
          }
          // Load application config with no cors
          const applicationConfig = await fetch(APPLICATION_CONFIG_URL, { cache: 'no-cache' }).then(response =>
            response.json(),
          );
          console.log('check maintenance', applicationConfig);

          if (applicationConfig.isMaintenanceActivated) {
            throw new Error('MAINTENANCE_ACTIVATED');
          } else {
            setisMaintenance(false);
          }
        } catch (error) {
          console.error(error);
          if (error.message === 'MAINTENANCE_ACTIVATED') {
            setisMaintenance(true);
          }
        }
      };
      await checkMaintenance();
      // setInterval(checkMaintenance, 1000 * 60 * 5);

      await onAppStartup();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoaded(true);
      await sleep(100); // Ensures children are rendered to avoid white screen jump
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  if (isLoaded) {
    if (isMaintenance) {
      return <Maintenance />;
    }
    return children;
  }

  return null;
};

export default AppLifecycleProvider;
