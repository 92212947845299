import { useContext, useMemo } from 'react';

import { upperFirst } from 'lodash';

import { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';

type BlackFridayAssetType =
  | 'blackFridayDe'
  | 'blackFridayEn'
  | 'blackFridayEs'
  | 'blackFridayFr'
  | 'blackFridayIt'
  | 'blackFridayNl';

export const useBlackFriday = () => {
  const { locale } = useContext(LocalizationContext);

  const blackFridayOffersUrl = useMemo(() => `${process.env.REACT_APP_CRM_URL}/app/subscriptions`, []);

  const blackFridayAsset: BlackFridayAssetType = useMemo(() => {
    return `blackFriday${upperFirst(locale)}` as BlackFridayAssetType;
  }, [locale]);

  const isBlackFridayPeriod = useMemo(() => {
    const now = dayjs();
    const november = now.year(now.year()).month(10);
    const lastDayOfNovember = november.endOf('month');

    const lastFridayOfNovember =
      lastDayOfNovember.day() >= 5
        ? lastDayOfNovember.subtract(lastDayOfNovember.day() - 5, 'day')
        : lastDayOfNovember.add(5 - lastDayOfNovember.day(), 'day');

    const startDate = lastFridayOfNovember.subtract(4, 'day'); // Monday (4 days before)
    const endDate = lastFridayOfNovember.add(2, 'day'); // Sunday (2 days after)

    // Check if today is between Monday and Sunday
    return now.isBetween(startDate, endDate, 'day', '[]');
  }, []);

  return { isBlackFridayPeriod, blackFridayAsset, blackFridayOffersUrl };
};
