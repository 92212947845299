import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Divider, Flex, Tooltip } from 'antd';

import { compact } from 'lodash';

import Icon from 'Components/Atoms/Icon';

import CatalogSelection from 'Forms/CatalogSelection';
import IncludedProductsForm, { IncludedProductValues } from 'Forms/IncludedProducts';

import { Colors, Metrics } from 'Themes';

import { LocalizationContext } from 'i18n';

import useGallery from 'Hooks/useGallery';

import {
  GalleryAccessPolicy,
  GalleryDownload,
  GalleryWorkmode,
  IncludedProductCoreFieldsFragment,
  IncludedRight,
} from 'Operations/__generated__/graphql';

import { GET_GALLERY_PRESET } from 'Operations/Queries/GalleryPreset/GetGalleryPreset';
import { GET_PRODUCTS_BY_TYPE } from 'Operations/Queries/Product/GetProductsByType';

import { UPDATE_GALLERY_INCLUDED_PRODUCTS } from 'Operations/Mutations/Gallery/UpdateGalleryIncludedProducts';
import { UPDATE_GALLERY_PRESET_INCLUDED_PRODUCTS } from 'Operations/Mutations/GalleryPreset/UpdateGalleryPresetIncludedProducts';

const PER_PAGE = 100;

interface Props {
  galleryId?: number;
  galleryPresetId?: number;
}

interface DigitalValues {
  includedProduct?: IncludedProductValues;
  includedProductForGuest?: IncludedProductValues;
  mandatoryProductId?: number;
}

interface PhysicalValues {
  includedProducts: IncludedProductValues[];
}

interface Values {
  digitalProducts: DigitalValues;
  physicalProducts: PhysicalValues;
  catalogId?: number;
}

const getIncludedProductsValues = (includedProducts: IncludedProductCoreFieldsFragment[]): IncludedProductValues[] =>
  includedProducts.map(({ product, hasAllIncluded, quantity, accessRole }) => ({
    productId: product.id,
    hasAllIncluded,
    quantity,
    accessRole,
  }));

const errorMessages: Record<string, string> = {
  GALLERY_HAS_ORDERS: 'app.message.error.galleryHasOrders.includedPhotosCount',
  DUPLICATE_INCLUDED_PRODUCTS: 'app.form.errors.duplicateIncludedProducts',
  MULTIPLE_DIGITAL_PRODUCTS_NOT_AUTHORIZED:
    'app.message.catalog.addProducts.error.multipleDigitalProductsNotAuthorized',
  MULTIPLE_PRODUCTS_NOT_AUTHORIZED_FOR_GUEST:
    'app.message.catalog.addProducts.error.multipleDigitalProductsNotAuthorized',
  DIGITAL_PRODUCT_REQUIRED_FOR_CLIENT: 'app.message.error.digitalProductRequiredForClient',
  CLIENT_PRODUCT_MUST_BE_ALL_INCLUDED: 'app.message.error.clientProductMustBeAllIncluded',
  GUEST_PRODUCT_MUST_BE_ALL_INCLUDED: 'app.message.error.guestProductMustBeAllIncluded',
  ERROR: 'app.message.error.somethingWentWrong',
};

const initialValues = {
  digitalProducts: {},
  physicalProducts: { includedProducts: [] },
};

const GalleryProductsTab = ({ galleryId, galleryPresetId }: Props) => {
  const { t } = useContext(LocalizationContext);
  const [values, setValues] = useState<Values>(initialValues);
  const [isError, setIsError] = useState(false);
  const defaultValues = useRef<Values>(initialValues);

  const { gallery } = useGallery({ id: galleryId });
  const { data: galleryPresetData } = useQuery(GET_GALLERY_PRESET, {
    skip: !galleryPresetId,
    variables: { where: { id: galleryPresetId as number } },
  });

  const [updateGalleryIncludedProducts, { loading: isLoadingUpdateGallery, error: galleryError }] = useMutation(
    UPDATE_GALLERY_INCLUDED_PRODUCTS,
  );
  const [updateGalleryPresetIncludedProducts, { loading: isLoadingUpdateGalleryPreset, error: galleryPresetError }] =
    useMutation(UPDATE_GALLERY_PRESET_INCLUDED_PRODUCTS);

  const errors = useMemo(() => {
    return (galleryError?.graphQLErrors || galleryPresetError?.graphQLErrors || []).map(
      e => errorMessages[(e.extensions?.code as string) || 'ERROR'],
    );
  }, [galleryError]);

  const { catalog, includedProducts, mandatoryProduct, workmode, accessPolicy } = useMemo(() => {
    if (gallery) {
      return {
        catalog: gallery.catalog,
        includedProducts: gallery.includedProducts,
        mandatoryProduct: gallery.mandatoryProduct,
        workmode: gallery.workmode,
        accessPolicy: gallery.accessPolicy,
      };
    } else if (galleryPresetData?.getGalleryPreset) {
      return {
        catalog: galleryPresetData.getGalleryPreset.catalog,
        includedProducts: galleryPresetData.getGalleryPreset.includedProducts,
        mandatoryProduct: galleryPresetData.getGalleryPreset.mandatoryProduct,
        workmode: galleryPresetData.getGalleryPreset.workmode,
        accessPolicy: galleryPresetData.getGalleryPreset.accessPolicy,
      };
    }

    return {
      accessPolicy: undefined,
      catalog: undefined,
      mandatoryProduct: undefined,
      workmode: undefined,
      includedProducts: [],
    };
  }, [gallery, galleryPresetData?.getGalleryPreset]);

  //TODO: get this from validatedOrders
  const minIncludedCount = 0;

  const {
    data: digitalProductsData,
    loading: isDigitalProductsLoading,
    fetchMore: fetchMoreDigitalProducts,
  } = useQuery(GET_PRODUCTS_BY_TYPE, {
    skip: !values.catalogId,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        catalogId: values.catalogId,
        isDigital: true,
      },
      paginate: {
        page: 1,
        perPage: PER_PAGE,
      },
    },
  });
  const digitalProductsPage = useMemo(
    () => Math.ceil((digitalProductsData?.getProductsByType?._count || 0) / PER_PAGE),
    [digitalProductsData?.getProductsByType?.edges],
  );

  const hasFetchAllDigitalProducts = useMemo(
    () =>
      !!digitalProductsData &&
      digitalProductsData?.getProductsByType._count === digitalProductsData?.getProductsByType.edges.length,
    [digitalProductsData?.getProductsByType?.edges],
  );

  const {
    data: physicalProductsData,
    loading: isPhysicalProductsLoading,
    fetchMore: fetchMorePhysicalProducts,
  } = useQuery(GET_PRODUCTS_BY_TYPE, {
    skip: !values.catalogId,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        catalogId: values.catalogId,
        isDigital: false,
      },
      paginate: {
        page: 1,
        perPage: PER_PAGE,
      },
    },
  });
  const physicalProductsPage = useMemo(
    () => Math.ceil((physicalProductsData?.getProductsByType?._count || 0) / PER_PAGE),
    [physicalProductsData?.getProductsByType?.edges],
  );
  const hasFetchAllPhysicalProducts = useMemo(
    () =>
      !!physicalProductsData &&
      physicalProductsData?.getProductsByType._count === physicalProductsData?.getProductsByType.edges.length,
    [physicalProductsData?.getProductsByType?.edges],
  );

  const hdDigitalProducts = useMemo(() => {
    const productEdges =
      digitalProductsData?.getProductsByType?.edges.filter(
        p =>
          p.digitalProductConfig?.downloadContent === GalleryDownload.HD ||
          p.digitalProductConfig?.downloadContent === GalleryDownload.HD_WEB,
      ) || [];

    return {
      _count: productEdges.length,
      edges: productEdges,
    };
  }, [digitalProductsData?.getProductsByType?.edges]);

  const digitalIncludedProducts = useMemo(() => {
    return includedProducts.filter(p => p.product.category.isDigital) || [];
  }, [includedProducts]);
  const physicalIncludedProducts = useMemo(() => {
    return includedProducts.filter(p => !p.product.category.isDigital) || [];
  }, [includedProducts]);

  /**
   * Reset values to default using gallery data
   */
  const resetValues = useCallback(() => {
    if (catalog?.id) {
      const digitalIncludedProduct = digitalIncludedProducts.find(p => p.accessRole === IncludedRight.CLIENT);
      const digitalIncludedProductForGuest = digitalIncludedProducts.find(p => p.accessRole === IncludedRight.GUEST);

      defaultValues.current = {
        digitalProducts: {
          includedProduct: digitalIncludedProduct
            ? getIncludedProductsValues([digitalIncludedProduct])?.[0]
            : undefined,
          includedProductForGuest: digitalIncludedProductForGuest
            ? getIncludedProductsValues([digitalIncludedProductForGuest])?.[0]
            : undefined,
          mandatoryProductId: mandatoryProduct?.id,
        },
        physicalProducts: { includedProducts: getIncludedProductsValues(physicalIncludedProducts) },
        catalogId: catalog.id,
      };

      setValues(defaultValues.current);
    }
  }, [catalog?.id, digitalIncludedProducts, mandatoryProduct, physicalIncludedProducts]);

  useEffect(() => {
    resetValues();
  }, [catalog?.id, digitalIncludedProducts, mandatoryProduct, physicalIncludedProducts, resetValues]);

  const hasChanged = useMemo(() => {
    return JSON.stringify(values) !== JSON.stringify(defaultValues.current);
  }, [values]);

  const onSubmit = useCallback(() => {
    const newIncludedProducts = compact([
      values.digitalProducts.includedProduct,
      values.digitalProducts.includedProductForGuest,
      ...values.physicalProducts.includedProducts,
    ]);

    if (galleryId && values.catalogId) {
      updateGalleryIncludedProducts({
        variables: {
          where: { id: galleryId },
          data: {
            catalogId: values.catalogId,
            includedProducts: newIncludedProducts,
            mandatoryProductId: values.digitalProducts.mandatoryProductId || null,
          },
        },
        onError() {},
      });
    }

    if (galleryPresetId && values.catalogId) {
      updateGalleryPresetIncludedProducts({
        variables: {
          where: { id: galleryPresetId },
          data: {
            catalogId: values.catalogId,
            includedProducts: newIncludedProducts,
            mandatoryProductId: values.digitalProducts.mandatoryProductId || null,
          },
        },
        onError() {},
      });
    }
  }, [galleryId, updateGalleryIncludedProducts, values]);

  return (
    <Flex gap="middle" vertical>
      <Flex justify="space-between">
        {values.catalogId && (
          <CatalogSelection
            catalogId={values.catalogId}
            // Reset values when catalogId changes
            onChange={catalogId => (catalogId !== values.catalogId ? setValues({ ...initialValues, catalogId }) : null)}
          />
        )}
        <Flex gap="small">
          <Button type="default" disabled={!hasChanged} onClick={resetValues}>
            {t('app.common.reset')}
          </Button>
          <Button type="primary" disabled={!hasChanged || isError} onClick={onSubmit}>
            {t('app.common.save')}
          </Button>
        </Flex>
      </Flex>
      {errors.map((error, i) => (
        <Alert key={i} message={t(error)} type="error" showIcon />
      ))}
      <Flex gap="middle">
        <Flex flex={1} gap="middle" vertical>
          {!!values?.catalogId && workmode && (
            <>
              {digitalProductsData && digitalProductsData?.getProductsByType._count === 0 && (
                <Alert message={t('app.gallery.noDigitalProducts')} type="info" showIcon />
              )}
              <IncludedProductsForm
                title={t('app.gallery.digitalIncludedProduct')}
                titleActions={
                  <Tooltip placement="top" title={t('app.gallery.includedProducts.digital.client.tooltip')}>
                    <Icon name="info-circle" size={Metrics.tooltipInfoIcon} color={Colors.primaryMain} />
                  </Tooltip>
                }
                isDigital
                workmode={workmode}
                products={digitalProductsData?.getProductsByType.edges || []}
                hasFetchAllProducts={hasFetchAllDigitalProducts}
                accessRole={IncludedRight.CLIENT}
                includedProducts={compact([values.digitalProducts.includedProduct])}
                loadMoreProducts={() =>
                  fetchMoreDigitalProducts({
                    variables: { paginate: { page: digitalProductsPage + 1, perPage: PER_PAGE } },
                  })
                }
                isLoading={isDigitalProductsLoading || isLoadingUpdateGallery || isLoadingUpdateGalleryPreset}
                minIncludedCount={1}
                maxSelectableProducts={1}
                emptyText={t('app.gallery.includedPhotosCount.noData')}
                onChange={({ includedProducts }) =>
                  setValues({
                    ...values,
                    digitalProducts: {
                      ...values.digitalProducts,
                      includedProduct: includedProducts[0],
                      // Reset guest product if client product is not all included
                      ...(!includedProducts?.[0]?.hasAllIncluded ? { includedProductForGuest: undefined } : {}),
                    },
                  })
                }
              />

              {workmode && accessPolicy === GalleryAccessPolicy.ACCESS_CODE && (
                <IncludedProductsForm
                  title={t('app.gallery.hasAllPhotosIncludedForGuest.label')}
                  titleActions={
                    <Tooltip placement="top" title={t('app.gallery.includedProducts.digital.guest.tooltip')}>
                      <Icon name="info-circle" size={Metrics.tooltipInfoIcon} color={Colors.primaryMain} />
                    </Tooltip>
                  }
                  isDigital
                  hideQuantity
                  workmode={workmode}
                  products={digitalProductsData?.getProductsByType.edges || []}
                  hasFetchAllProducts={
                    !!digitalProductsData &&
                    digitalProductsData?.getProductsByType._count ===
                      digitalProductsData?.getProductsByType.edges.length
                  }
                  accessRole={IncludedRight.GUEST}
                  includedProducts={compact([values.digitalProducts.includedProductForGuest])}
                  loadMoreProducts={() =>
                    fetchMoreDigitalProducts({
                      variables: { paginate: { page: digitalProductsPage + 1, perPage: PER_PAGE } },
                    })
                  }
                  isLoading={isDigitalProductsLoading || isLoadingUpdateGallery || isLoadingUpdateGalleryPreset}
                  minIncludedCount={!!values.digitalProducts.includedProduct?.hasAllIncluded ? 1 : 0}
                  maxSelectableProducts={!!values.digitalProducts.includedProduct?.hasAllIncluded ? 1 : 0}
                  emptyText={
                    !!values.digitalProducts.includedProduct?.hasAllIncluded ? (
                      t('app.gallery.includedPhotosCount.noData')
                    ) : workmode !== GalleryWorkmode.RETOUCH_FIRST ? (
                      <Alert message={t('app.gallery.hasAllPhotosIncludedForGuest.info')} type="info" showIcon />
                    ) : (
                      t('app.gallery.includedPhotosCount.noData')
                    )
                  }
                  onChange={({ includedProducts }) =>
                    setValues({
                      ...values,
                      digitalProducts: { ...values.digitalProducts, includedProductForGuest: includedProducts?.[0] },
                    })
                  }
                />
              )}
              {hdDigitalProducts._count === 0 ? (
                <Alert message={t('app.gallery.noHDDigitalProducts')} type="info" showIcon />
              ) : (
                <IncludedProductsForm
                  title={t('app.gallery.enableMandatoryProduct')}
                  titleActions={
                    <Tooltip placement="top" title={t('app.gallery.includedProducts.mandatory.tooltip')}>
                      <Icon name="info-circle" size={Metrics.tooltipInfoIcon} color={Colors.primaryMain} />
                    </Tooltip>
                  }
                  isDigital
                  hideQuantity
                  workmode={workmode}
                  products={hdDigitalProducts.edges || []}
                  hasFetchAllProducts={hasFetchAllDigitalProducts}
                  includedProducts={
                    values.digitalProducts.mandatoryProductId
                      ? compact([
                          {
                            productId: values.digitalProducts.mandatoryProductId,
                            accessRole: IncludedRight.CLIENT,
                            hasAllIncluded: false,
                          },
                        ])
                      : []
                  }
                  loadMoreProducts={() =>
                    fetchMoreDigitalProducts({
                      variables: { paginate: { page: digitalProductsPage + 1, perPage: PER_PAGE } },
                    })
                  }
                  isLoading={isDigitalProductsLoading || isLoadingUpdateGallery || isLoadingUpdateGalleryPreset}
                  minIncludedCount={1}
                  maxSelectableProducts={1}
                  emptyText={t('app.gallery.mandatoryProduct.noData')}
                  onChange={({ includedProducts }) =>
                    setValues({
                      ...values,
                      digitalProducts: {
                        ...values.digitalProducts,
                        mandatoryProductId: includedProducts?.[0]?.productId,
                      },
                    })
                  }
                />
              )}
            </>
          )}
        </Flex>
        <Divider type="vertical" style={{ height: 'auto' }} />
        <Flex flex={1} gap="middle" vertical>
          {!!values.catalogId && workmode && (
            <IncludedProductsForm
              title={t('app.gallery.physicalIncludedProduct')}
              titleActions={
                <Tooltip placement="top" title={t('app.gallery.includedProducts.physical.client.tooltip')}>
                  <Icon name="info-circle" size={Metrics.tooltipInfoIcon} color={Colors.primaryMain} />
                </Tooltip>
              }
              workmode={workmode}
              products={physicalProductsData?.getProductsByType.edges || []}
              hasFetchAllProducts={hasFetchAllPhysicalProducts}
              includedProducts={values.physicalProducts.includedProducts}
              accessRole={IncludedRight.CLIENT}
              loadMoreProducts={() =>
                fetchMorePhysicalProducts({
                  variables: { paginate: { page: physicalProductsPage + 1, perPage: PER_PAGE } },
                })
              }
              isLoading={isPhysicalProductsLoading || isLoadingUpdateGallery || isLoadingUpdateGalleryPreset}
              minIncludedCount={1}
              emptyText={t('app.gallery.includedPhotosCount.noData')}
              onChange={({ includedProducts, errors }) => {
                setValues({ ...values, physicalProducts: { includedProducts } });
                setIsError(!!errors);
              }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GalleryProductsTab;
