import React, { HTMLAttributes, useCallback, useContext, useMemo } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { Alert, Badge, Button, Dropdown, Flex } from 'antd';
import { createStyles } from 'antd-style';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import Icon from 'Components/Atoms/Icon';

import MenuLink, { Props as MenuLinkProps } from 'Components/Molecules/MenuLink';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { MenuItemNode, useMenuRight } from 'Hooks/MenuRightProvider';
import { NotificationDrawerContext } from 'Hooks/NotificationDrawerProvider';
import { useBlackFriday } from 'Hooks/useBlackFriday';
import { useContactModal } from 'Hooks/useContactModal';
import { useGalleryModal } from 'Hooks/useGalleryModal';
import { useRoles } from 'Hooks/useRoles';

import { UserConfigVar } from 'Operations/Cache';

import { GET_NOTIFICATIONS_UNREAD_COUNT } from 'Operations/Queries/Notification/GetNotificationsUnreadCount';

import updateUserConfig from 'Operations/Mutations/User/UpdateUserConfig';

interface Props extends HTMLAttributes<HTMLDivElement> {
  links: MenuLinkProps[];
}

const useStyles = createStyles(({ css, token, responsive }) => ({
  menuRightColumn: css`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    background-color: #fff;
    padding: ${token.sizeLG}px ${token.size}px;
    box-shadow: -16px 0 32px -16px rgba(0, 0, 0, 0.15) inset;
    overflow-y: auto;

    transition: all ease 250ms;
    width: 268px;

    &.collapsed {
      width: 76px;
    }
    ${responsive.lg} {
      width: 76px;
    }

    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
  `,
  container: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${token.size}px;
  `,
  buttons: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: ${token.size}px;
    &.collapsed {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    ${responsive.lg} {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  `,
  subMenuContainer: css`
    padding: ${token.sizeSM}px 0 ${token.size}px ${token.sizeSM}px;
    margin-left: ${token.size}px;

    display: flex;
    flex-direction: column;
    gap: ${token.size}px;

    border-left: 1px solid ${token.colorBorder};
  `,
  actionButton: css`
    border-color: ${token.menuRightActionButtonColor};

    &:hover {
      background: ${token.menuRightActionButtonColor} !important;
      border-color: ${token.menuRightActionButtonColor}!important;
      svg {
        fill: #fff;
      }
    }

    svg {
      fill: ${token.menuRightActionButtonColor};
    }
  `,
  responsiveContainer: css`
    &.collapsed {
      display: none;
    }
    ${responsive.lg} {
      display: none;
    }
  `,
  collapseButton: css`
    ${responsive.lg} {
      display: none;
    }
  `,
  blackFridayLink: css`
    display: block;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 4px 16px rgba(141, 90, 228, 0.6);
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
      box-shadow: 0 4px 12px rgba(141, 90, 228, 1);
    }

    &:active {
      transform: scale(0.99);
      box-shadow: 0 4px 12px rgba(141, 90, 228, 1);
    }
  `,
  blackFridayImg: css`
    width: 100%;
    height: auto;
  `,
}));

const MenuRightColumn = ({ links, className, ...props }: Props) => {
  const { openDrawer } = useContext(NotificationDrawerContext);
  const { t } = useContext(LocalizationContext);
  const { createGallery } = useGalleryModal();
  const { createContact } = useContactModal();
  const { items } = useMenuRight();
  const { styles, cx } = useStyles();
  const { isLabo } = useRoles();
  const { isBlackFridayPeriod, blackFridayAsset, blackFridayOffersUrl } = useBlackFriday();
  const userConfig = useReactiveVar(UserConfigVar);

  const { data: notificationsData } = useQuery(GET_NOTIFICATIONS_UNREAD_COUNT, {
    fetchPolicy: 'cache-and-network',
  });

  const { rootContent, menuContent } = useMemo(() => {
    const rootContent = items?.filter(item => !item.parentPath).map(item => item.content);
    const menuContent: {
      [key: string]: MenuItemNode[] | null;
    } = {};

    items?.forEach(item => {
      if (item.parentPath) {
        if (!menuContent[item.parentPath]) {
          menuContent[item.parentPath] = [];
        }
        (menuContent[item.parentPath] as MenuItemNode[]).push(item.content);
      }
    });

    return { rootContent, menuContent };
  }, [items]);

  const addMenu = useMemo(() => {
    const items: ItemType[] = [];
    if (!isLabo) {
      items.push({
        key: 'gallery',
        label: t('app.action.addGallery'),
        icon: <Icon name="galleries" />,
        onClick: () => createGallery(),
      });
      items.push({
        key: 'contact',
        label: t('app.action.addContact'),
        icon: <Icon name="contacts" />,
        onClick: createContact,
      });
    }

    return { items };
  }, [createGallery, isLabo, t]);

  const isCollapsed = useMemo(() => !!userConfig?.isMenuCollapsed, [userConfig?.isMenuCollapsed]);

  const toggleMenu = useCallback(() => {
    updateUserConfig({ isMenuCollapsed: !isCollapsed });
  }, [isCollapsed, updateUserConfig]);

  return (
    <section className={cx(styles.menuRightColumn, isCollapsed && 'collapsed', className)} {...props}>
      <div className={styles.container}>
        <section className={cx(styles.buttons, isCollapsed && 'collapsed')}>
          <Badge count={notificationsData?.getNotificationsUnreadCount.unread || 0}>
            <Button size="large" shape="circle" className={styles.actionButton}>
              <Icon name="notifications" size={22} onClick={openDrawer} />
            </Button>
          </Badge>
          <Dropdown menu={addMenu}>
            <Button size="large" shape="circle" className={styles.actionButton}>
              <Icon name="add" size={22} />
            </Button>
          </Dropdown>
        </section>
        {links.map(({ href = '', text = '', textParams, ...link }) => (
          <div key={`menu-right-column-${href}`}>
            <MenuLink href={`/app/${href}`} text={t(text, textParams)} collapse={isCollapsed} {...link} />
            {menuContent[href] && (
              <div className={cx(styles.subMenuContainer, styles.responsiveContainer, isCollapsed && 'collapsed')}>
                {menuContent[href]}
              </div>
            )}
          </div>
        ))}
        <div className={cx(styles.responsiveContainer, isCollapsed && 'collapsed')}>{rootContent}</div>
      </div>
      <Flex vertical justify="flex-end" align="end" gap="middle" className={styles.collapseButton}>
        {!userConfig?.isMenuCollapsed && isBlackFridayPeriod && (
          <a href={blackFridayOffersUrl} target="_blank" className={cx(styles.blackFridayLink)}>
            <img src={Images[blackFridayAsset]} className={cx(styles.blackFridayImg)} />
          </a>
        )}
        {/* {!userConfig?.isMenuCollapsed && (
          <Alert type="warning" message={t('app.menu.warning.title')} description={t('app.menu.warning.description')} />
        )} */}

        <Button size="large" shape="circle" onClick={toggleMenu}>
          <Icon name={userConfig?.isMenuCollapsed ? 'sidebar-collapse' : 'sidebar-expand'} size={22} />
        </Button>
      </Flex>
    </section>
  );
};

export default MenuRightColumn;
